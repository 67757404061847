import { library } from '@fortawesome/fontawesome-svg-core'
import { isIOS } from 'react-device-detect';
import { faCreditCard,faDice, faCommentAlt, faMoneyBillWave, faNewspaper, faWrench, faExchangeAlt, faStore  } from '@fortawesome/free-solid-svg-icons'
library.add(faCreditCard, faDice, faCommentAlt, faMoneyBillWave, faNewspaper, faWrench, faExchangeAlt, faStore)

const categories =  [
    {
        name: '去中心化金融',
        displayIos: false,
        icon: 'money-bill-wave',
        color: '#5cd879',
        dapps: [
            {
                name: 'Compound',
                url: 'https://app.compound.finance/',
                description: '在你的以太币上赚取利息',
                icon: './images/dapps/compound.finance.png'
            },
            {
                name: 'MakerDAO CDP',
                url: 'https://oasis.app/',
                description: '使用Dai进行交易，借入和储蓄',
                icon: './images/dapps/cdp.makerdao.com.png'
            },
            {
                name: 'PoolTogether',
                url: 'https://app.pooltogether.us/',
                description: '与奖金挂钩的储蓄账户。 通过省钱，您就有机会赢取奖品',
                icon: './images/dapps/pooltogether.us.png'
            },
            {
                name: 'DeFi保护者',
                url: 'https://defisaver.com/',
                description: '一站式DeFi协议管理解决方案',
                icon: './images/dapps/defi_saver.png'
            },
            {
                name: 'p代币',
                url: 'https://dapp.ptokens.io/',
                description: '使您的加密货币与任何区块链兼容！',
                icon: './images/dapps/pTokens.png'
            },
            {
                name: 'Zapper',
                url: 'https://zapper.fi',
                description: '在一个简单的界面中管理你的DeFi资产和负债',
                icon: './images/dapps/zapper.svg'
            },
            {
                name: 'Yearn',
                url: 'https://yearn.finance/',
                description: '利用借贷服务优化代币借贷的聚合者生态系统',
                icon: './images/dapps/yearn.finance.png'
            },
            {
                name: 'Aave',
                url: 'https://aave.com/',
                description: '开源的非托管协议，可创建货币市场',
                icon: './images/dapps/aave.svg'
            },
            {
                name: 'Zerion',
                url: 'https://zerion.io/',
                description: 'Zerion是从一个地方构建和管理整个DeFi产品组合的最简单方法',
                icon: './images/dapps/zerion.io.png'
            },
            {
                name: '代币集',
                url: 'https://www.tokensets.com/',
                description: '管理你的加密货币投资组合',
                icon: './images/dapps/tokensets.com.png'
            },
            {
                name: '泡菜金融',
                url: 'https://pickle.finance',
                description: '稳定的稳定币',
                icon: './images/dapps/pickle.finance.jpeg'
            },
            {
                name: '互相的关系',
                url: 'https://nexusmutual.io/',
                description: '以人为本的保险替代方案',
                icon: './images/dapps/nexusmutual.io.png'
            },
            {
                name: 'Curve',
                url: 'https://www.curve.fi/',
                description: '使用高级粘合曲线创造深层的链上流动',
                icon: './images/dapps/curve.fi.png'
            },
        ],
    },
    {
        name: '去中心化交易所',
        displayIos: false,
        icon: 'exchange-alt',
        color: '#bbb9f3',
        dapps: [
            {
                name: 'Uniswap交易所',
                url: 'https://uniswap.exchange/',
                description: '交换和交易代币',
                icon: './images/dapps/uniswap.exchange.png'
            },
            {
                name: ' BNB交易所',
                url: 'https://bsc.mdex.com/#/referral?id=rKDszb5y',
                description: '专业的币安智能链BSC交易平台',
                icon: './images/dapps/mdex.png'
            },
            {
                name: ' HT交易所',
                url: 'https://ht.mdex.com/#/referral?id=a6WwzJZs',
                description: '专业火币生态链HECO交易平台',
                icon: './images/dapps/mdex.jpeg'
            },
            {
                name: 'OKexchain交易所',
                url: 'https://app.kswap.finance/#/swap',
                description: '交换和交易代币',
                icon: './images/dapps/kswap.png'
            },
            {
                name: 'ETH菩提交易所',
                url: 'https://puti.app',
                description: '支持中文的以太坊去中心化代币交易所',
                icon: './images/dapps/puti.png'
            },
            {
                name: '马蹄MATIC交易',
                url: 'https://quickswap.exchange',
                description: '快速交易MATIC主链资产',
                icon: './images/dapps/quickswap.png'
            },
            {
                name: 'FANTOM金融',
                url: 'https://pwawallet.fantom.network',
                description: '官方原生FTM金融方案',
                icon: './images/dapps/fantomdefi.png'
            },
            {
                name: 'BSC金字塔交易所',
                url: 'https://pyramid.exchange',
                description: '排名前三的BSC去中心化代币交易所',
                icon: './images/dapps/pyramid.png'
            },
            {
                name: 'HECO火炬交易所',
                url: 'https://pytorch.exchange',
                description: '全球领先的HECO代币交易所',
                icon: './images/dapps/pytorch.png'
            },
            {
                name: '云加密交易所',
                url: 'https://yunjiami.com',
                description: '带推广系统的聚合交易所',
                icon: './images/dapps/yunjiami.jpeg'
            },
            {
                name: '  代币群发吧',
                url: 'https://qunfaba.com',
                description: '免费快速群发代币，免费群发以太币，代币治理神器。',
                icon: './images/dapps/qunfaba.png'
            },
            {
                name: 'dYdX',
                url: 'https://trade.dydx.exchange/',
                description: '提供永久保证金和现货交易的去中心化非托管交易平台',
                icon: './images/dapps/dydx.png'
            },
            {
                name: 'Kyber交易所',
                url: 'https://kyberswap.com/',
                description: '交换代币的简单方法',
                icon: './images/dapps/kyber.network.png'
            },
            {
                name: 'Tokenlon',
                url: 'https://tokenlon.im/',
                description: 'Tokenlon去中心化交易所，由0x协议提供支持',
                icon: './images/dapps/tokenlon.png'
            },
            {
                name: '1inch',
                url: 'https://1inch.exchange',
                description: '1inch是具有最佳去中心化交易所代币价格和DeFi入口的去中心化聚合器',
                icon: './images/dapps/1inch.svg'
            },
            {
                name: 'Totle交易所',
                url: 'https://totle.exchange',
                description: '以最优的价格交换任何ERC-20代币',
                icon: './images/dapps/totle.jpeg'
            },
            {
                name: '雷达中继',
                url: 'https://relay.radar.tech/',
                description: '在DeFi中以最优的价格进行简单安全的钱包到钱包交易',
                icon: './images/dapps/radar_relay.png'
            },
            {
                name: '空中交易',
                url: 'https://instant.airswap.io/',
                description: '轻松，安全且无需交易费即可交易代币',
                icon: './images/dapps/instant.airswap.io.png'
            },
            {
                name: 'Balancer',
                url: 'https://balancer.exchange/#/swap',
                description: '在所有Balancer流动性池中以无需信任的方式交换ERC20代币。',
                icon: './images/dapps/balancer.exchange.png'
            },
            {
                name: '抹茶',
                url: 'https://matcha.xyz/',
                description: '所有人的简单加密交易',
                icon: './images/dapps/matcha.png'
            },
        ]   
    },
    {
        name: '艺术品与收藏品',
        displayIos: false,
        icon: 'store',
        color: '#F29D62',
        dapps: [
            {
                name: '哑剧',
                url: 'https://miime.io/',
                description: '哑剧是一个NFT市场，面向日本乃至全球的区块链游戏玩家',
                icon: './images/dapps/miime.png'
            },
              {
                name: 'Mintable',
                url: 'https://mintable.app',
                description: 'Mintable是次世代NFT市场，是交易NFT并创建自己的最简单的地方。',
                icon: './images/dapps/mintable.png'
            },
            {
                name: '外海',
                url: 'https://opensea.io/',
                description: '购买，出售和发现稀有数字物品',
                icon: './images/dapps/opensea.io.png'
            },
            {
                name: '知识源头',
                url: 'https://knownorigin.io/',
                description: '发现并收集稀有的数字艺术品',
                icon: './images/dapps/known_origin.png'
            },
            {
                name: 'Mintbase',
                url: 'https://mintbase.io',
                description: '领先的不可替代的代币创建工具和市场',
                icon: './images/dapps/mintbase.png'  
            },
            {
                name: 'Axie无限',
                url: 'https://axieinfinity.com/#',
                description: '收集并养育称为Axie的幻想生物',
                icon: './images/dapps/axieinfinity.com.png'
            },
            {
                name: 'Sorare',
                url: 'https://sorare.com/',
                description: '全球梦幻足球。 收集限量版数字收藏品',
                icon: './images/dapps/sorare.jpg'
            },
            {
                name: '三叶草网络',
                url: 'https://clovers.network',
                description: '发现，收集和交易密码图标',
                icon: './images/dapps/clovers.png'
            },
            {
                name: '加密猫',
                url: 'https://www.cryptokitties.co/',
                description: '收集，繁殖和交易独特的加密猫',
                icon: './images/dapps/cryptokitties.co.png'
            },
            {
                name: '超级稀有',
                url: 'https://superrare.co/',
                description: '发现并收集数字作品',
                icon: './images/dapps/superrare.co.jpg'
            },
            {
                name: '稀有品',
                url: 'https://rarible.com/',
                description: '创建和出售数字收藏品',
                icon: './images/dapps/rarible.com.jpg'
            },
            {
                name: '创客广场',
                url: 'https://makersplace.com/',
                description: '收集真正珍贵的数字艺术作品',
                icon: './images/dapps/makersplace.com.jpg'
            },
            {
                name: '我的加密英雄',
                url: 'https://www.mycryptoheroes.net/home',
                description: '收集英雄并战斗',
                icon: './images/dapps/my_crypto_heroes.jpg'
            },
            {
                name: 'CryptoDozer市场',
                url: 'https://market.playdapp.io',
                description: '从销售到购买，市场都是可靠且快速的',
                icon: './images/dapps/cryptodozer.io.png'
            },
            {
                name: '勇敢的边疆英雄',
                url: 'https://bravefrontierheroes.com',
                description: '在回合制任务和战斗中使用英雄。',
                icon: './images/dapps/brave_frontier_heroes.png'
            },
           {
                name: '区块Cuties',
                url: 'https://blockchaincuties.com/',
                description: 'Cuties是指冒险中的猫，狗和熊。',
                icon: './images/dapps/blockchaincuties.com.png'
            },
            {
                name: 'F1 Delta 时代',
                url: 'https://www.f1deltatime.com/',
                description: '拥有F1 Delta的收藏品和股份以赚取收益',
                icon: './images/dapps/f1deltatime.com.png'
            },
            {
                name: '超级加密货币',
                url: 'http://mcp3d.com',
                description: '收集土地并建设区块链城市。',
                icon: './images/dapps/megacryptopolis.png'
            },
        ]   
    },
    {
        name: '赚币',
        displayIos: false,
        icon: 'credit-card',
        color: '#1098FC',
        dapps: [
            {
                name: ' 以太网络',
                url: 'https://yitaiwangluo.com',
                description: '以太网络是一个以太坊 EVM 网络列表集合。',
                icon: './images/dapps/yitaiwangluo.png'
            },
            {
                name: ' 以太地址',
                url: 'https://yitaidizhi.com',
                description: '以太地址是使用你的Web浏览器生成以太坊靓号地址的开源工具。',
                icon: './images/dapps/yitaidizhi.png'
            },
            {
                name: 'Git币',
                url: 'https://gitcoin.co/',
                description: '因从事开源项目而获得加密货币报酬',
                icon: './images/dapps/gitcoin.co.png'
            },
            {
                name: '本地以太坊',
                url: 'https://localethereum.com/',
                description: '在130多个国家/地区获得以太币点对点交易',
                icon: './images/dapps/localethereum.com.png'
            },
            {
                name: '一分',
                url: 'https://beta.cent.co/',
                description: '通过创建内容赚取以太币',
                icon: './images/dapps/cent.co.png'
            },
            {
                name: '以太坊域名服务',
                url: 'https://app.ens.domains/',
                description: '为你所有的加密货币钱包，去中心化的网站获得一个域名',
                icon: './images/dapps/ens.jpg'
            },
            {
                name: 'Gnosis安全多签',
                url: 'https://gnosis-safe.io/app/#/',
                description: '管理集体数字资产',
                icon: './images/dapps/gnosis_safe.png'
            },
            {
                name: 'Microsponsors',
                url: 'https://microsponsors.io',
                description: 'Microsponsors是代币化时间的拍卖行',
                icon: './images/dapps/microsponsors.png'
            },
            {
                name: 'MythX',
                url: 'https://mythx.io/',
                description: '以太坊的智能合约安全服务',
                icon: './images/dapps/mythx.png'
            },
        ],
    },
    {
        name: '发币',
        displayIos: false,
        icon: 'wrench',
        color: '#838c96',
        dapps: [
            {
                name: '   代币群发吧',
                url: 'https://qunfaba.com',
                description: '快速分散群发代币，支持多链',
                icon: './images/dapps/qunfaba.png'
            },
            {
                name: '  代币工厂',
                url: 'https://tokenfactory.net/#',
                description: '免费的多链多语言支持发币程序',
                icon: './images/dapps/tokenfactory.png'
            },
            {
                name: ' Arbitrum发币',
                url: 'https://arbitrum.pyramid.finance',
                description: '安全快速创建自己的Arbitrum代币',
                icon: './images/dapps/arbitrum.png'
            },
            {
                name: ' Optimism发币',
                url: 'https://optimism.pyramid.finance',
                description: '安全创建Optimism发币代币',
                icon: './images/dapps/optimism.png'
            },
            {
                name: ' Celo发币',
                url: 'https://celo.pyramid.finance',
                description: '快速创建自己的CELO代币',
                icon: './images/dapps/celo.png'
            },
            {
                name: 'ETH一键发币',
                url: 'https://yjfb.net',
                description: '创建自己的以太坊智能合约，无需编程技巧',
                icon: './images/dapps/yjfb.png'
            },
            {
                name: '欧易链发币',
                url: 'https://okdaibi.com',
                description: '超简单部署OKexchain代币',
                icon: './images/dapps/okdaibi.png'
            },
            {
                name: 'HECO发币',
                url: 'https://huofabi.com',
                description: '在不到一分钟的时间内，在火币生态链Heco使用最常用的HRC20代币智能合约生成器创建HRC20代币。',
                icon: './images/dapps/huofabi.png'
            },
            {
                name: 'BSC发币',
                url: 'https://bidaibi.com',
                description: '无需代码，快速在币安智能链BSC上创建自己的BEP20代币',
                icon: './images/dapps/bidaibi.png'
            },
            {
                name: '马蹄链发币',
                url: 'https://mtfabi.com',
                description: '无需登录。无需设置。无需编程。快速创建MATIC上的代币',
                icon: './images/dapps/mtfabi.png'
            },
            {
                name: 'Fantom发币',
                url: 'https://fdaibi.com',
                description: '极速创建Fantom上的代币,无需任何代码技巧',
                icon: './images/dapps/fdaibi.png'
            },
        ]   
    },
    {
        name: '社交',
        displayIos: false,
        icon: 'comment-alt',
        color: '#F8CD45',
        dapps: [
            {
                name: '3Box',
                url: 'https://3box.io/hub',
                description: '以太坊的社交工具',
                icon: './images/dapps/3box.io.png'
            },
            {
                name: 'Kauri',
                url: 'https://kauri.io/',
                description: '去中心化知识库中的以太坊开发内容',
                icon: './images/dapps/kauri.io.png'
            },
            {
                name: '泡沫地图',
                url: 'https://map.foam.space',
                description: '创建，整理和搜索共识驱动的地图',
                icon: './images/dapps/foam.space.png'
            },
            {
                name: '阿拉贡',
                url: 'https://aragon.org/discover/',
                description: '创建和管理社区，公司，组织',
                icon: './images/dapps/aragon.org.png'
            },
        ],
    },
    {
        name: '市场动向',
        displayIos: false,
        icon: 'newspaper',
        color: '#FF849A',
        dapps: [
            {
                name: 'Popula',
                url: 'https://popula.com/',
                description: '由Civil和以太坊提供支持的新闻',
                icon: './images/dapps/popula.com.png'
            },
            {
                name: '币桌',
                url: 'https://www.coindesk.com/',
                description: '区块链新闻',
                icon: './images/dapps/coindesk.com.png'
            },
            {
                name: '币虎',
                url: 'https://www.coingecko.com/en',
                description: '加密货币价格和市值',
                icon: './images/dapps/coingecko.com.png'
            },
            {
                name: '解密新闻',
                url: 'https://decrypt.co/',
                description: '有关以太坊，比特币和Web3的新闻',
                icon: './images/dapps/decrypt.co.jpg'
            },
            {
                name: 'Defi脉冲',
                url: 'https://defipulse.com/',
                description: 'DeFi的分析和排名中心',
                icon: './images/dapps/defipulse.com.png'
            },
        ],
    }
];

// hide any of the above on iOS when displayIos is false
const filtered = categories.filter(({ displayIos = true }) => !(isIOS && !displayIos));

export default filtered;
